<div class="flex flex-row ml-10 mr-10 p-10 text-[#ffffff]">
    <div class="flex flex-col pr-10 items-center">
        <div class="text-center text-lg">
            Dynamic Inventory
        </div>
        <div class=" pb-5">
            Our integrated system keeps track of products from purchase to sale, so you never sell what you never have
        </div>
        <img src="assets/images/example_screenshot1.png" alt="example screenshot 1" class="w-[400px] h-[650px]" />
    </div>
    <div class="flex flex-col pr-10 items-center">
        <div class="text-center text-lg">
            Profit Expense Tracking
        </div>
        <div class="pb-5">  
            Since cost of goods (COG'S) are input when an item is found and purchased, Auto Keep will keep this information. No more long hours on excel or searching for recipts
        </div>
        <img src="assets/images/example_screenshot2.png" alt="example screenshot 2" class="w-[400px] h-[650px]" />
    </div>
    <div class="flex flex-col items-center">
        <div class="text-center text-lg">
            Product Search
        </div>
        <div class="pb-5">
            Easily find products available for sale through barcode scanning or searching products to find what items provide a positive ROI
        </div>
        <img src="assets/images/example_screenshot3.png" alt="example screenshot 3" class="w-[400px] h-[650px]" />
    </div>
</div>