<div class="flex flex-col">
    <h1>Welcome to Auto Keep!</h1>
    <h3>Let's get you ready to use the platform!</h3>
    <h5>Video Tutorial on how to use AutoKeep</h5>
    <div class="m-[200px]">
        TODO: Add video here with tutorial
    </div>
    <h5>
        Or, press Complete Setup to skip the tutorial, and get started immediately!
    </h5>
</div>