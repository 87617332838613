<div class="flex flex-col items-center" [formGroup]="marketplaceStepForm">
    <div class="text-5xl m-4 text-center pb-9">
        Please Select your Primary Marketplace
    </div>
    <div class="text-lg text-center pb-9">
        You will be able to add additional regions once sign up is completed.
    </div>
    <mat-form-field>
        <mat-label>Region</mat-label>
        <mat-select formControlName="region" required >
            @for (region of regionsAndMarketplaces; track $index) {
                <mat-option [value]="region.name">
                    {{region.name}}
                </mat-option>
            }

        </mat-select>
    </mat-form-field>
    @if(selectedRegion !== null){
        <mat-form-field>
            <mat-label>Marketplace</mat-label>
            <mat-select formControlName="marketplace" required>
                @for (marketplace of selectedRegion.marketplaces; track $index) {
                    <mat-option [value]="marketplace.name">
                        {{marketplace.name}}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>
    }
    @if(selectedRegion !== null && selectedMarketplace !== null && !marketplaceConfirmed){
        <button mat-raised-button color="primary" (click)="confirm()" [disabled]="confirming">
            @if(confirming){
                <mat-spinner [diameter]="20"></mat-spinner>
            }
            @else {
                Confirm
            }
        </button>
    }
    @if(marketplaceStepForm.valid){
        <app-animated-checkmark></app-animated-checkmark>
    }
</div>