<mat-card class="items-center">
    <mat-card-header>
        <mat-card-title>Your Subscription</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        @if(isLoading){
            <div class="flex flex-row justify-center">
                <mat-spinner diameter="50"></mat-spinner>
            </div>
        }
        @else if(!isLoading && !!mySubscription){
            <div class="flex flex-col">
                <div class="flex flex-row justify-start text-2xl p-4">
                    <div class="pr-4">
                        <mat-icon>subscriptions</mat-icon>
                    </div>
                    <div>
                        {{ mySubscription.name }}
                    </div>
                </div>
                <div class="flex flex-row justify-start text-2xl p-4">
                    <div class="pr-4">
                        <mat-icon>description</mat-icon>
                    </div>
                    <div>
                        {{ mySubscription.description }}
                    </div>
                </div>
                <div class="flex flex-row justify-start text-2xl p-4">
                    <div class="pr-4">
                        <mat-icon>attach_money</mat-icon>
                    </div>
                    <div>
                        {{ mySubscription.monthlyPrice | currency }}
                    </div>
                </div>
            </div>
        }
        @else if(!isLoading && !mySubscription){
            <div class="flex flex-col">
                <div class="flex flex-row justify-center m-4">
                    <div>
                        You do not have a current subscription.
                    </div>
                </div>
                <button mat-raised-button color="primary" class="m-4">Subscribe</button>
            </div>
        }
    </mat-card-content>
    <mat-card-actions class=" w-full">
        <div class="flex flex-row justify-between w-full">
            <button mat-raised-button color="warn" (click)="cancelChanges()" class="w-full" [disabled]="!mySubscription">Cancel Subscription</button>
            <button mat-raised-button color="primary" (click)="updateProfile()" class="w-full" [disabled]="true">
                Update Subscription
            </button>
        </div>
    </mat-card-actions>
</mat-card>