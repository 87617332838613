import { Component } from '@angular/core';
import { ProfileFormComponent } from './profile-form/profile-form.component';
import { SubscriptionsFormComponent } from './subscriptions-form/subscriptions-form.component';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [ProfileFormComponent, SubscriptionsFormComponent],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent{

}
