<div class="flex flex-col">
    <div mat-dialog-title class="text-4xl">
        Are you sure you would like to cancel your subscription?
    </div>
    <div mat-dialog-content class="text-lg">
        To gain access to Auto Keep again, you will have to re-subscribe at the rates provided at that time.
    </div>
    <div mat-dialog-actions class="flex flex-row justify-center">
        <button mat-raised-button color="warn" (click)="cancelSubscription()">Cancel Subscription</button>
        <button mat-raised-button color="primary" (click)="close()">No, I would like to keep my Subscription</button>
    </div>
</div>