import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponse } from '../models/base-response';
import { environment } from '../../environments/environment';
import { RegionsWithMarketplaces } from '../models/regionswithmarketplaces';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceService {

  constructor(private httpClient: HttpClient) { }

  getRegionsAndMarketplaces(){
    return this.httpClient.get<BaseResponse<RegionsWithMarketplaces[]>>(`${environment.apiConfig.uri}api/v1/Marketplace`); 
  }

  giveUserMarketplace(marketPlaceId: number){
    return this.httpClient.post(`${environment.apiConfig.uri}api/v1/Marketplace/User`, {marketPlaceId: marketPlaceId});
  }

  getMyMarketplaces(){
    return this.httpClient.get<BaseResponse<RegionsWithMarketplaces[]>>(`${environment.apiConfig.uri}api/v1/Marketplace/User`); 
  }
}
