import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AnimatedCheckmarkComponent } from '../../shared/animated-checkmark/animated-checkmark.component';
import { MarketplaceService } from '../../../services/marketplace.service';
import { RegionsWithMarketplaces } from '../../../models/regionswithmarketplaces';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-marketplace-step',
  standalone: true,
  imports: [MatButtonModule, ReactiveFormsModule, MatFormFieldModule, AnimatedCheckmarkComponent, MatSelectModule, MatProgressSpinnerModule],
  templateUrl: './marketplace-step.component.html',
  styleUrl: './marketplace-step.component.scss'
})
export class MarketplaceStepComponent implements OnInit{

  @Input() marketplaceStepForm!: FormGroup;

  regionsAndMarketplaces: RegionsWithMarketplaces[] = [];

  confirming = false;

  constructor(private marketplaceService: MarketplaceService){}

  ngOnInit(): void {
    this.getMyMarketplaces();
    this.getRegionsAndMarketplaces();
  }

  getMyMarketplaces(){
    this.marketplaceService.getMyMarketplaces().subscribe((response) => {
      if(response.data.length > 0){
        this.marketplaceStepForm.get('region')?.setValue(response.data[0].name, {emitEvent: false});
        this.marketplaceStepForm.get('region')?.disable();
        this.marketplaceStepForm.get('marketplace')?.setValue(response.data[0].marketplaces[0].name, {emitEvent: false});
        this.marketplaceStepForm.get('marketplace')?.disable();
        this.marketplaceStepForm.get('marketplaceConfirmed')?.setValue(true);
      }
    });
  }
  getRegionsAndMarketplaces(){
    this.marketplaceService.getRegionsAndMarketplaces().subscribe((response) => {
      this.regionsAndMarketplaces = response.data;
    });
  }

  confirm(){
    this.confirming = true;
    const id = this.regionsAndMarketplaces.find(x => x.name === this.selectedRegion?.name)?.marketplaces.find(x => x.name === this.selectedMarketplace?.name)?.id;
      if(id){
        this.marketplaceService.giveUserMarketplace(id).subscribe(() =>{
          this.marketplaceStepForm.get('marketplaceConfirmed')?.setValue(true);
          this.confirming = false;
          this.marketplaceStepForm.get('region')?.disable();
          this.marketplaceStepForm.get('marketplace')?.disable();
        });
      }
  }
  
  get selectedRegion(){
    return this.regionsAndMarketplaces.find(x => x.name === this.marketplaceStepForm.get('region')?.value) ?? null;
  }
  get selectedMarketplace(){
    return this.selectedRegion?.marketplaces.find(x => x.name === this.marketplaceStepForm.get('marketplace')?.value) ?? null;
  }
  get marketplaceConfirmed(){
    return this.marketplaceStepForm.get('marketplaceConfirmed')?.value ?? false;
  }
}
