import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  injectStripe,
  StripeElementsDirective,
  StripePaymentElementComponent,
} from 'ngx-stripe';
import { environment } from '../../../../environments/environment';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { CurrencyPipe } from '@angular/common';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-subscription-payment',
  standalone: true,
  imports: [
    StripePaymentElementComponent,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    StripeElementsDirective,
    MatCardModule,
    MatButtonModule,
    CurrencyPipe
  ],
  templateUrl: './subscription-payment.component.html',
  styleUrl: './subscription-payment.component.scss'
})
export class SubscriptionPaymentComponent implements OnInit{

  ngOnInit(): void {
    this.elementsOptions.clientSecret = this.clientSecret;
  }

  constructor(private authService: MsalService) { 
  }

  paying = false;

  @ViewChild(StripePaymentElementComponent) paymentElement!: StripePaymentElementComponent;
  @Input() clientSecret = '';
  @Input() price = 0;

  @Output() paymentSuccess = new EventEmitter<void>();

  checkoutForm = new FormGroup({
    name: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    zipCode: new FormControl('', Validators.required),
    city: new   FormControl('', Validators.required),
  });
  
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    appearance: {
      theme: 'stripe',
      labels: 'floating',
      variables: {
        colorPrimary: '#598B88',
      },
    },
  };

  readonly stripe = injectStripe(environment.publishableStripeKey);

  pay(){
    this.paying = true;

    this.stripe.confirmPayment({
      elements: this.paymentElement.elements,
      confirmParams:{
        payment_method_data:{
          billing_details:{
            name: this.checkoutForm.get('name')?.value,
            email: this.authService.instance?.getActiveAccount()?.username,
            address:{
              city: this.checkoutForm.get('city')?.value,
              line1: this.checkoutForm.get('address')?.value,
              postal_code: this.checkoutForm.get('zipCode')?.value
            }
          }
        },
        },
        redirect:'if_required'
      }).subscribe({
        next: (response) => {
          if(response.error){
            this.paying = false;
            return;
          }
          this.paying = false;
          this.paymentSuccess.emit();
        },
        error: () => {
          this.paying = false;
        }
      });

  }

}
