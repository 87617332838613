export const environment = {
    production: false,
    environmentName: 'sandbox',
    msalConfig: {
        auth: {
            clientId: '3523d03c-86c9-46f8-ae96-dad0bdbc993a',
            authority: 'https://autokeepb2csandbox.b2clogin.com/autokeepb2csandbox.onmicrosoft.com/B2C_1_SignInSignUp',
            authorityDomain: "autokeepb2csandbox.b2clogin.com"
        }
    },
    apiConfig: {
        scopes: ['https://autokeepb2csandbox.onmicrosoft.com/f1691588-db60-48b7-8799-c0c0691c7b4d/App.Read'],
        uri: 'https://autokeep-web-sandbox.azurewebsites.net/'
    },
    amazonFBMAppId: 'amzn1.sp.solution.2cd487d6-7d83-4e71-8a7c-f770abfdfa15',
    publishableStripeKey: 'pk_test_51PRQe107Fxxq03SoLVuKkVDvJJrHkGvyY8tIim9ZNz1h40XMDGv3MEAoNCfGOA3PtwemZY7k9UYuGagzOrIt3NgR00YsnOSMQ8'
};
