import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { SubscriptionType } from '../models/subscription-type';
import { BaseResponse } from '../models/base-response';
import { CreateSubscriptionResponse } from '../models/create-subscription-response';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private httpClient: HttpClient) { }

  getSubscriptionTypes(){
    return this.httpClient.get<BaseResponse<SubscriptionType[]>>(`${environment.apiConfig.uri}api/v1/Subscription/SubscriptionType`);
  }

  createSubscription(subscriptionId: number) {
    return this.httpClient.put<BaseResponse<CreateSubscriptionResponse>>(`${environment.apiConfig.uri}api/v1/Subscription`, {id: subscriptionId})
  }

  cancelSubscription(){
    return this.httpClient.delete<BaseResponse<null>>(`${environment.apiConfig.uri}api/v1/Subscription`);
  }

  getMySubscription(){
    return this.httpClient.get<HttpResponse<BaseResponse<SubscriptionType>>>(`${environment.apiConfig.uri}api/v1/Subscription`);
  }
}
