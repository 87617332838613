import { Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionService } from '../../services/subscription.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-cancel-subscription',
  standalone: true,
  imports: [MatDialogModule, MatSnackBarModule, MatButtonModule],
  templateUrl: './cancel-subscription.component.html',
  styleUrl: './cancel-subscription.component.scss'
})
export class CancelSubscriptionComponent {

  constructor(private subscriptionService: SubscriptionService, private matSnackBar: MatSnackBar, private matDialogRef: MatDialogRef<CancelSubscriptionComponent>) {

  }

  cancelSubscription(){
    this.subscriptionService.cancelSubscription().subscribe({
      next: () => {
        this.matSnackBar.open('Subscription Canceled', 'Close', { duration: 5000, verticalPosition: 'top', });
      },
      error: () => {
        this.matSnackBar.open('Subscription Cancellation Failed. Please Contact Support', 'Close', { verticalPosition: 'top', });
      },
      complete: () => {
        this.matDialogRef.close();
      }
    });
  }

  close(){
    this.matDialogRef.close();
  }
}
