import { Component } from '@angular/core';
import { FeaturesComponent } from './features/features.component';
import { PricingComponent } from './pricing/pricing.component';
import { SupportComponent } from './support/support.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [FeaturesComponent, PricingComponent, SupportComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {


}
