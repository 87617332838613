<div class="flex flex-col items-center">
    <div class="text-5xl m-4 text-center pb-9">
        Are you a member of Amazon FBM, and have been approved for a Professional Amazon Seller Central Account?
    </div>
    <div class="text-lg text-center pb-9">
        If not, please sign up for an Amazon FBM account and get approved for a Professional Amazon Seller Central Account. Once you have been approved, please return to this page to continue the setup process, we would love to have you.
    </div>
    <div class="flex flex-row items-center">
        @if(!confirmed)
        {
            <button mat-button color="warn" class="w-48 min-h-24" (click)="confirm(false)" [disabled]="confirming">
                <div class="text-4xl" >No</div>
            </button>
            <button mat-button color="primary" class="w-48 min-h-24" (click)="confirm(true)" [disabled]="confirming">
                @if(confirming)
                {
                    <mat-spinner [diameter]="20"></mat-spinner>
                }
                @else {
                    <div class="text-4xl" >Yes</div>
                }
            </button>
        }
        @else {
            <app-animated-checkmark></app-animated-checkmark>
        }
    </div>
</div>