import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanActivateFn, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { MissingSubscriptionComponent } from '../components/shared/missing-subscription/missing-subscription.component';
import { getRolesFromToken } from '../extensions';

export const roleGuard: CanActivateFn = (route, _) => {

  const msalService = inject(MsalService);
  const router = inject(Router);
  const matDialog = inject(MatDialog);

  const roles: string = getRolesFromToken(msalService.instance.getActiveAccount()?.idTokenClaims);

  const valid = route.data['roles'].some((role: string) => roles.includes(role));
  
  if(!valid &&  route.data['roles'].includes('ActiveSubscriptionUser')){
    router.navigate(['/']);

    matDialog.open(MissingSubscriptionComponent, {
      
    });
  } else if(!valid){
    router.navigate(['/']);
  }

  return valid;
};
