import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionPaymentComponent } from '../../shared/subscription-payment/subscription-payment.component';
import { SubscriptionType } from '../../../models/subscription-type';
import { SubscriptionService } from '../../../services/subscription.service';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { CurrencyPipe } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormGroup } from '@angular/forms';
import { AnimatedCheckmarkComponent } from '../../shared/animated-checkmark/animated-checkmark.component';
import { BaseResponse } from '../../../models/base-response';
@Component({
  selector: 'app-subscription-step',
  standalone: true,
  imports: [
    SubscriptionPaymentComponent, 
    MatCardModule, 
    MatButtonModule,
    CurrencyPipe,
    MatProgressSpinnerModule,
    MatTooltipModule,
    AnimatedCheckmarkComponent
  ],
  templateUrl: './subscription-step.component.html',
  styleUrl: './subscription-step.component.scss'
})
export class SubscriptionStepComponent implements OnInit{
  
  @Input() subscriptionForm!: FormGroup;

  subscriptionTypes: SubscriptionType[] = [];
  clientSecret: string| null = null;
  subscriptionChosen: SubscriptionType | null = null;

  paymentSuccess(){
    this.subscriptionForm.get('paymentSuccess')?.setValue(true);
  }

  constructor(private subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
    this.getSubscriptionTypes();
    this.getMySubscription();
  }

  getMySubscription(){
    this.subscriptionService.getMySubscription().subscribe((resp) =>{
      if(!resp){
        return;
      }
      this.clientSecret = 'empty'
      this.subscriptionChosen = (resp as unknown as BaseResponse<SubscriptionType>).data!;
      this.paymentSuccess();
    });
  }

  getSubscriptionTypes(){
    this.subscriptionService.getSubscriptionTypes().subscribe(response => {
      this.subscriptionTypes = response.data;
    });
  }

  chooseSubscription(subscription: SubscriptionType){
    this.subscriptionForm.get('paymentSkipped')?.setValue(false);
    this.subscriptionChosen = subscription;
    this.subscriptionService.createSubscription(subscription.id).subscribe(response => {
      this.clientSecret = response.data.subscriptionClientSecret;
    });
  }

  skip(){
    this.subscriptionForm.get('paymentSkipped')?.setValue(true);
  }

  get paymentComplete(){
    return this.subscriptionForm.get('paymentSuccess')?.value ?? false;
  }

}
