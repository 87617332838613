<mat-card class="w-96 items-center">
    <mat-card-header class="text-center">
        <mat-card-title class="pb-4"><div class="text-6xl">$25</div></mat-card-title>
        <mat-card-subtitle class="pb-4">Monthly Subscription</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="text-center mb-8">
        <div class="flex flex-row">
            For $25 dollars a month unlock all the features Auto Keep has to offer 
        </div>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary">Get Started</button>
    </mat-card-actions>
</mat-card>