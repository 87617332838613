<mat-toolbar color="primary" class="sticky top-0 z-50">
  <!-- come back to tihs -->
  <a class="title main-text-color flex flex-row" href="/">Auto Keep</a> 
  <div class="pl-4 text-red-600">{{environmentName}}</div>
  @if (home) {
    <a class="main-text-color ml-2" mat-button fragment="features-nav" (click)="navigateToSection('features-nav')" tabindex="0" (keydown)="navigateToSection('features-nav')">Features</a>
    <a class="main-text-color ml-2" mat-button fragment="pricing-nav" (click)="navigateToSection('pricing-nav')" tabindex="0" (keydown)="navigateToSection('features-nav')">Pricing</a>
    <a class="main-text-color ml-2" mat-button fragment="support-nav" (click)="navigateToSection('support-nav')" tabindex="0" (keydown)="navigateToSection('features-nav')">Support</a>
  }

  <div class="flex flex-1"></div>

  
  @if (!loginDisplay) {
  <button mat-raised-button (click)="loginRedirect()">Login</button>
  }

  @if (loginDisplay) {
    @if(hasActiveSubscription){
      <button mat-raised-button (click)="dashboardRedirect()" class="mr-5">Dashboard</button>
    }
    @else {
      <button mat-raised-button (click)="openSubscribeModal()" class="mr-5">Subscribe</button>
    }
  <app-profile-icon></app-profile-icon>
  }
</mat-toolbar>

<div>
    <router-outlet></router-outlet>
</div>