<mat-card class="items-center">
    <mat-card-header>
        <mat-card-title>Profile</mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-full">
        @if(isLoading){
            <div class="flex flex-row justify-center">
                <mat-spinner diameter="50"></mat-spinner>
            </div>
        }
        @else {
            <div class="flex flex-col" [formGroup]="form">
                <div class="flex flex-row justify-between">
                    <mat-form-field class="m-4 w-full">
                        <mat-label>First Name</mat-label>
                        <input matInput formControlName="firstName">
                    </mat-form-field>
                    <mat-form-field class="m-4 w-full">
                        <mat-label>Last Name</mat-label>
                        <input matInput formControlName="lastName">
                    </mat-form-field>
                </div>
                <div class="flex flex-row justify-between">
                    <mat-form-field class="m-4 w-full">
                        <mat-label>Display Name</mat-label>
                        <input matInput formControlName="displayName">
                    </mat-form-field>
                    <mat-form-field class="m-4 w-full">
                        <mat-label>Email</mat-label>
                        <input matInput required formControlName="email">
                    </mat-form-field>
                </div>
                <div class="flex flex-row justify-between">
                    <mat-form-field class="m-4 w-full">
                        <mat-label>Phone</mat-label>
                        <input matInput formControlName="phone">
                    </mat-form-field>
                    <mat-form-field class="m-4 w-full">
                        <mat-label>City</mat-label>
                        <input matInput formControlName="city">
                    </mat-form-field>
                </div>
                <div class="flex flex-row justify-between">
                    <mat-form-field class="m-4 w-full">
                        <mat-label>State</mat-label>
                        <input matInput formControlName="state">
                    </mat-form-field>
                    <mat-form-field class="m-4 w-full">
                        <mat-label>Country</mat-label>
                        <input matInput formControlName="country">
                    </mat-form-field>
                </div>
            </div>
        }    
    </mat-card-content>
    <mat-card-actions class=" w-full">
        <div class="flex flex-row justify-between w-full">
            <button mat-raised-button color="warn" (click)="cancelChanges()" class="w-full">Cancel</button>
            <button mat-raised-button color="primary" (click)="updateProfile()" [disabled]="updateProfileDisabled || isLoadingUpdate" class="w-full">
                @if(isLoadingUpdate){
                    <mat-spinner diameter="20"></mat-spinner>
                }
                @else
                {
                    Update
                }
            </button>
        </div>
    </mat-card-actions>
</mat-card>