<div class="h-full flex flex-col">
    <h1 mat-dialog-title class="text-center">New User Setup</h1>
    <div mat-dialog-content class="h-full flex-grow flex flex-col justify-between">
      <div class="flex-grow text-center">
        Looks like you have not completed your first time setup! Let's get you started
        <mat-horizontal-stepper [linear]="true" #stepper labelPosition="bottom" class="h-full">
          <mat-step [stepControl]="fbmStepForm">
            <ng-template matStepLabel>Amazon FBM Confirmation</ng-template>
            <app-confirm-fbm-step [fbmStepForm]="fbmStepForm" class="h-full"></app-confirm-fbm-step>
          </mat-step>
          <mat-step [stepControl]="marketplaceStepForm">
            <ng-template matStepLabel>Main Marketplace</ng-template>
            <app-marketplace-step class="h-full" [marketplaceStepForm]="marketplaceStepForm"></app-marketplace-step>
          </mat-step>
          <mat-step [stepControl]="consentStepForm">
            <ng-template matStepLabel>Amazon Consent</ng-template>
            <app-consent-step class="h-full" [consentStepForm]="consentStepForm"></app-consent-step>
          </mat-step>
          <mat-step [stepControl]="subscriptionStepForm">
            <ng-template matStepLabel>Subscription</ng-template>
            <app-subscription-step class="h-full" [subscriptionForm]="subscriptionStepForm"></app-subscription-step>
          </mat-step>
          <mat-step [stepControl]="reviewStepForm">
            <ng-template matStepLabel>Review</ng-template>
            <app-review-step class="h-full" [reviewStepForm]="reviewStepForm"></app-review-step>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
    <div class="mt-4 w-full" mat-dialog-actions>
      <div class="flex flex-row justify-between w-full">
        @if(backVisible){
          <button class="w-full" mat-button (click)="back()" [disabled]="confirming">Back</button>
        }
        @else{
          <button class="w-full" mat-button (click)="logout()">Logout</button>
        }
        @if(confirmVisible){
          <button class="w-full" mat-button (click)="confirm()" [disabled]="confirming">Complete Setup</button> 
        }
        @else {
          <button class="w-full" mat-button [disabled]="nextDisabled" (click)="next()">Next</button>
        }
      </div>
    </div>
</div>
  