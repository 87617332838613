import { Component } from '@angular/core';

@Component({
  selector: 'app-animated-checkmark',
  standalone: true,
  imports: [],
  templateUrl: './animated-checkmark.component.html',
  styleUrl: './animated-checkmark.component.scss'
})
export class AnimatedCheckmarkComponent {

}
