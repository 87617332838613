<div class="flex flex-col items-center">
    <div class="text-5xl m-4 text-center pb-9">
        Please give Amazon FBM access to Access Auto Keep. The Link will redirect you to Amazon Seller Central to give consent.
    </div>
    <div class="text-lg text-center pb-9">
        If not, once you have given consent, please return to this page to continue the setup process.
    </div>
    <div class="flex flex-row items-center">
        @if(!confirmed)
        {
            <button mat-button color="primary" class="w-48 min-h-24" (click)="confirm()"><div class="text-4xl" >Grant</div></button>
        }
        @else {
            <app-animated-checkmark></app-animated-checkmark>
        }
    </div>
</div>