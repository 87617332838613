import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { environment } from '../../environments/environment';
import { BaseResponse } from '../models/base-response';
import { UserPayload } from '../models/user-payload';
import { ConsentAndSetup } from '../models/consent-and-setup';
import { AmazonConsentRequest } from '../models/amazon-consent-request';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }

  getUser(){
    return this.httpClient.get<BaseResponse<User>>(`${environment.apiConfig.uri}api/v1/User`);
  }

  updateUser(userPayload: UserPayload){
    return this.httpClient.post(`${environment.apiConfig.uri}api/v1/User`, userPayload)
  }

  getUserConsentAndSetup(){
    return this.httpClient.get<BaseResponse<ConsentAndSetup>>(`${environment.apiConfig.uri}api/v1/User/ConsentAndFirstTimeSetup`);
  }

  confirmFBM(){
    return this.httpClient.post(`${environment.apiConfig.uri}api/v1/User/ConfirmFBM`, null);
  }

  grantAmazonConsent(amazonConsentRequest: AmazonConsentRequest){
    return this.httpClient.post(`${environment.apiConfig.uri}api/v1/User/GrantAmazonConsent`, amazonConsentRequest);
  }

  completeFirstTimeSetup(){
    return this.httpClient.post(`${environment.apiConfig.uri}api/v1/User/CompleteFirstTimeSetup`, null);
  }
}
