<div class="flex flex-col w-full main-gradient">
    <div class="text-center pt-5 m-5" >
        <h1 class="main-text-color">Welcome to Auto Keep</h1>
        <h3 class="main-text-color" id="features-nav">An Integrated Service for Product search, Inventory Management, and Bookkeeping</h3>
    </div>
    <div class="flex flex-col items-center">
        <app-features class="mb-48"></app-features>
        <div id="pricing-nav" class="text-center pt-5 m-5">
            <h1 class="main-text-color">Plans and Pricing</h1>
            <h3 class="main-text-color">Join today and let auto keep help your business grow.</h3>
        </div>
        <app-pricing class="mt-10 mb-48"></app-pricing>
        <div id="support-nav" class="text-center pt-5 m-5">
            <h1 class="main-text-color">Support</h1>
            <h3 class="main-text-color">Get the help you need to see your business thrive</h3>
        </div>
        <app-support></app-support>
    </div>
</div>