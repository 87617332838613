import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AnimatedCheckmarkComponent } from '../../shared/animated-checkmark/animated-checkmark.component';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-consent-step',
  standalone: true,
  imports: [MatButtonModule, ReactiveFormsModule, MatFormFieldModule, AnimatedCheckmarkComponent],
  templateUrl: './consent-step.component.html',
  styleUrl: './consent-step.component.scss'
})
export class ConsentStepComponent implements OnInit{

  @Input() consentStepForm!: FormGroup;

  constructor(private userService: UserService){}

  ngOnInit(): void {
    this._generateRandomState();
  }

  confirm(){
    window.open(`https://sellercentral.amazon.com/apps/authorize/consent?application_id=${environment.amazonFBMAppId}&state=${this.state}${environment.production ?'' : '&version=beta'}`, '_blank');
  }

  @HostListener('window:storage', ['$event'])
  onStorageEvent(event: StorageEvent) {
    if (event.key === this.state ) {
      this.userService.grantAmazonConsent(JSON.parse(event.newValue ?? '')).subscribe(() => {
        this.consentStepForm.get('hasAmazonConsent')?.setValue(true);
      })
    }
  }

  get confirmed(){
    return this.consentStepForm.get('hasAmazonConsent')?.value ?? false;
  }

  private _generateRandomState() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 12; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    this.consentStepForm.get('state')?.setValue(result);
  }

  get state(){
    return this.consentStepForm.get('state')?.value;
  }
}
