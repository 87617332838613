import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { ProfileIconComponent } from './components/profile-icon/profile-icon.component';
import { environment } from '../environments/environment';
import { UserService } from './services/user.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { RegistrationStepperComponent } from './components/registration-stepper/registration-stepper.component';
import { getRolesFromToken } from './extensions';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, MsalModule, RouterOutlet, RouterLink, MatToolbarModule, MatButtonModule, MatMenuModule, RouterModule, ProfileIconComponent, MatDialogModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'Auto Keep';
  loginDisplay = false;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService, 
    private msalBroadcastService: MsalBroadcastService, 
    private router: Router,
    private userService: UserService,
    private matDialog: MatDialog) {}

  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe();

    this._setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
    )
    .subscribe(() => {
      if (this.authService.instance.getAllAccounts().length === 0) {
        window.location.pathname = "/";
      } else {
        this._setLoginDisplay();
      }
    });
  
    this.msalBroadcastService.msalSubject$.subscribe((result: EventMessage) => {
      if(result.eventType === EventType.ACQUIRE_TOKEN_FAILURE && result.interactionType === 'silent'){
        //todo - popup and ask if they would like to re-sign in instead of just redirecting.
        this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      }
    })
    
  this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this._setLoginDisplay();
      this._checkAndSetActiveAccount();
      this.userService.getUserConsentAndSetup().subscribe((consentResponse) => {
        if(!this.router.url.split('?')[0].endsWith('/callback') && (!consentResponse.data.hasConsented || !consentResponse.data.hasCompletedFirstTimeSetup)) {
          this.matDialog.open(RegistrationStepperComponent, {width: '75vw', height: '80%', disableClose: true, minWidth: '75vw', minHeight: '80vh', 
            data: {hasConsented: consentResponse.data.hasConsented, hasCompletedFirstTimeSetup: consentResponse.data.hasCompletedFirstTimeSetup, hasConfirmedFBM: consentResponse.data.hasConfirmedFBM}});
        }
      });
    })
  }

  private _setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  private _checkAndSetActiveAccount(){
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  public navigateToSection(section: string) {
    window.location.hash = '';
    window.location.hash = section;
  }
  
  get home(){
    return this.router.url === '' || this.router.url === '/' || this.router.url.startsWith('/#');
  }

  get environmentName(){
    return !environment.production ? environment.environmentName : '';
  }

  get hasActiveSubscription(){
    return getRolesFromToken(this.authService.instance.getActiveAccount()?.idTokenClaims).split(',').includes('ActiveSubscriptionUser');
  }

  dashboardRedirect(){
    this.router.navigate(['/dashboard/home'])
  }

  openSubscribeModal(){
    throw new Error('Method not implemented.');
  }
}
