<mat-card>
    <mat-card-header>
        <mat-card-title>
            Billing Information
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div [formGroup]="checkoutForm">
            <div class="flex flex-row mb-4">
                <mat-form-field class="flex-1 m-2">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" />
                </mat-form-field>
                <mat-form-field class="flex-1 m-2">
                    <mat-label>Address</mat-label>
                    <input matInput formControlName="address" />
                  </mat-form-field>
            </div>
            <div class="flex flex-row">
                <mat-form-field class="flex-1 m-2">
                    <mat-label>ZIP Code</mat-label>
                    <input matInput formControlName="zipCode" />
                </mat-form-field>
                <mat-form-field class="flex-1 m-2">
                    <mat-label>City</mat-label>
                    <input matInput formControlName="city" />
                  </mat-form-field>
            </div>
            @if (elementsOptions.clientSecret) {
                <ngx-stripe-elements
                  [stripe]="stripe"
                  [elementsOptions]="elementsOptions"
                >
                  <ngx-stripe-payment />
                </ngx-stripe-elements>
                }
        </div>
        <button mat-raised-button [disabled]="paying || checkoutForm.invalid" (click)="pay()">
            @if(!paying){
                Pay {{ price | currency }}
            }
            @else {
                <mat-spinner diameter="20"></mat-spinner>
            }
        </button>
    </mat-card-content>
</mat-card>