@if(!!subscriptionChosen && clientSecret){
    @if(paymentComplete){
        <div class="text-center">
            <h1>Payment Complete</h1>
            <p>Thank you for your payment. You can now use all Auto Keep features.</p>
        </div>

        <app-animated-checkmark></app-animated-checkmark>
    }
    @else {
        <app-subscription-payment [price]="subscriptionChosen.monthlyPrice" [clientSecret]="clientSecret" (paymentSuccess)="paymentSuccess()"></app-subscription-payment>
    }
}
@else {
    <div class="flex flex-col">
        @for (subscriptionType of subscriptionTypes; track $index) {
            <mat-card class="flex flex-row text-center align-middle justify-center h-full m-2">
                <mat-card-header class="flex flex-row text-center align-middle justify-center mb-6">
                    <mat-card-title>
                        {{ subscriptionType.name }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="flex flex-row justify-center mb-4">
                        {{ subscriptionType.description }}
                    </div>
                    <div class="flex flex-row justify-center mb-4 text-lg">
                        <div>
                            {{ (subscriptionType.monthlyPrice) | currency }} 
                        </div>
                        <div class="text-sm pt-1 ml-3">
                            Monthly Subscription
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions class="flex flex-row text-center align-middle justify-center">
                    <button mat-raised-button color="primary" (click)="chooseSubscription(subscriptionType)">
                        @if(subscriptionChosen === subscriptionType){
                            <mat-spinner [diameter]="20"></mat-spinner>
                        }
                        @else {
                            Choose
                        }
                    </button>
                </mat-card-actions>
            </mat-card>
        }
    </div> 
}