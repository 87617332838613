import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SubscriptionService } from '../../../services/subscription.service';
import { SubscriptionType } from '../../../models/subscription-type';
import { BaseResponse } from '../../../models/base-response';
import { MatIconModule } from '@angular/material/icon';
import { CurrencyPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { CancelSubscriptionComponent } from '../../cancel-subscription/cancel-subscription.component';

@Component({
  selector: 'app-subscriptions-form',
  standalone: true,
  imports: [MatCardModule, ReactiveFormsModule, MatInputModule, MatButtonModule, MatProgressSpinnerModule, MatIconModule, CurrencyPipe],
  templateUrl: './subscriptions-form.component.html',
  styleUrl: './subscriptions-form.component.scss'
})
export class SubscriptionsFormComponent implements OnInit{

  isLoading = false;

  mySubscription: SubscriptionType | null = null;

  constructor(private subscriptionService: SubscriptionService, private matDialog: MatDialog) { }
  
  ngOnInit(): void {
    this.getSubscription();
  }

  getSubscription(){
    this.isLoading = true;
    this.subscriptionService.getMySubscription().subscribe({
      next: (resp) => {
        if(!resp){
          return;
        }
        this.mySubscription = (resp as unknown as BaseResponse<SubscriptionType>).data!;
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }


  cancelChanges(){
    this.matDialog.open(CancelSubscriptionComponent, {
    })
  }
  updateProfile(){
    throw new Error('Method not implemented.');
  }

  // get updateSubscriptionDisabled(){
  //   return false; // todo implement
  // }
}
