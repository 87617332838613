import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { AnimatedCheckmarkComponent } from '../../shared/animated-checkmark/animated-checkmark.component';

@Component({
  selector: 'app-notify-ready-to-close',
  standalone: true,
  imports: [MatDialogModule, AnimatedCheckmarkComponent],
  templateUrl: './notify-ready-to-close.component.html',
  styleUrl: './notify-ready-to-close.component.scss'
})
export class NotifyReadyToCloseComponent {

}
