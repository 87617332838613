import { Routes } from '@angular/router';
import { ProfileComponent } from './components/profile/profile.component';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './components/home/home.component';
import { SupportComponent } from './components/home/support/support.component';
import { CallbackComponent } from './components/callback/callback.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardHomeComponent } from './components/dashboard/dashboard-home/dashboard-home.component';
import { DashboardInventoryComponent } from './components/dashboard/dashboard-inventory/dashboard-inventory.component';
import { roleGuard } from './guards/role.guard';

export const routes: Routes = [
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'callback',
        component: CallbackComponent
    },
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'support',
        component: SupportComponent
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        data:{
            roles: ['ActiveSubscriptionUser', 'Admin']
        },
        canActivate: [MsalGuard, roleGuard],
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', component: DashboardHomeComponent },
            { path: 'inventory', component: DashboardInventoryComponent}
        ]
    },
    {
        path: '**',
        redirectTo: '/'
    }
];
