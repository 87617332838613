import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/user';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { UserPayload } from '../../../models/user-payload';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-profile-form',
  standalone: true,
  imports: [MatCardModule, ReactiveFormsModule, MatInputModule, MatButtonModule, MatProgressSpinner, MatFormFieldModule],
  templateUrl: './profile-form.component.html',
  styleUrl: './profile-form.component.scss'
})
export class ProfileFormComponent implements OnInit{

  isLoading = false;
  isLoadingUpdate = false;
  originalDataForm!: FormGroup;
  form!: FormGroup;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.form = this._installFormValues();
    this.originalDataForm = this._installFormValues();
      
    this.getUser();
      
  }

  getUser(){
    this.isLoading = true;
    this.userService.getUser().subscribe(user => {
      this.isLoading = false;
      this._setFormValues(this.form, user?.data);
      this._setFormValues(this.originalDataForm, user?.data);
    });
  }
    
  //todo --add and enforce validation
  private _installFormValues(){
    const form = new FormGroup({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      displayName: new FormControl(''),
      email: new FormControl(''),
      phone: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      country: new FormControl(''),
    });

    form.get('email')?.disable();

    return form;
  }

  private _setFormValues(formGroup: FormGroup, user: User){
    formGroup.get('firstName')?.setValue(user?.firstName);
    formGroup.get('lastName')?.setValue(user?.lastName);
    formGroup.get('displayName')?.setValue(user?.displayName);
    formGroup.get('email')?.setValue(user?.email);
    formGroup.get('phone')?.setValue(user?.phoneNumber);
    formGroup.get('city')?.setValue(user?.city);
    formGroup.get('state')?.setValue(user?.state);
    formGroup.get('country')?.setValue(user?.country);
  }

  cancelChanges(){
    Object.keys(this.form.controls).forEach(control => {
      this.form.get(control)?.setValue(this.originalDataForm.get(control)?.value);
    });
  }

  updateProfile(){
    this.isLoadingUpdate = true;
    this.userService.updateUser(({
      firstName: this.form.get('firstName')?.value,
      lastName: this.form.get('lastName')?.value,
      displayName: this.form.get('displayName')?.value,
      phoneNumber: this.form.get('phone')?.value,
      city: this.form.get('city')?.value,
      state: this.form.get('state')?.value,
      country: this.form.get('country')?.value
    } as UserPayload)).subscribe(() => {
      this.isLoadingUpdate = false;
      this.getUser();
    });
  }

  get updateProfileDisabled(){
    return JSON.stringify(this.form.value) === JSON.stringify(this.originalDataForm.value);
  }
}
