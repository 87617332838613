import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AnimatedCheckmarkComponent } from '../../shared/animated-checkmark/animated-checkmark.component';
import { UserService } from '../../../services/user.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-confirm-fbm-step',
  standalone: true,
  imports: [MatButtonModule, ReactiveFormsModule, MatFormFieldModule, AnimatedCheckmarkComponent, MatProgressSpinnerModule],
  templateUrl: './confirm-fbm-step.component.html',
  styleUrl: './confirm-fbm-step.component.scss'
})
export class ConfirmFbmStepComponent {
  @Input() fbmStepForm!: FormGroup;

  confirming = false;

  constructor(private userService: UserService){}

  confirm(value: boolean){
    if(value){
      this.confirming = true;
      this.userService.confirmFBM().subscribe(() => {
        this.confirming = false;
        this.fbmStepForm.get('hasProfessionalAccount')?.setValue(value);
      });
    }
  }

  get confirmed(){
    return this.fbmStepForm.get('hasProfessionalAccount')?.value ?? false;
  }
}