import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NotifyReadyToCloseComponent } from './notify-ready-to-close/notify-ready-to-close.component';

@Component({
  selector: 'app-callback',
  standalone: true,
  imports: [MatDialogModule],
  templateUrl: './callback.component.html',
  styleUrl: './callback.component.scss'
})
export class CallbackComponent implements OnInit{
  constructor(private route: ActivatedRoute, private matDialog: MatDialog) {}

  ngOnInit() {
      const params = this.route.snapshot.queryParamMap;
      const spapi_oauth_code = params.get('spapi_oauth_code');
      const selling_partner_id = params.get('selling_partner_id');
      const state = params.get('state');
      if (state) {
        localStorage.setItem(state, `{"SpApiOauthCode":"${spapi_oauth_code}","SellingPartnerId":"${selling_partner_id}"}`);
      }
      
      this.matDialog.open(NotifyReadyToCloseComponent, {
        disableClose: true,
        width: '400px',
        height: '400px',
      })

  }
}
