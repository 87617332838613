import { AfterContentChecked, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { ConsentStepComponent } from './consent-step/consent-step.component';
import { MarketplaceStepComponent } from './marketplace-step/marketplace-step.component';
import { ReviewStepComponent } from './review-step/review-step.component';
import { SubscriptionStepComponent } from './subscription-step/subscription-step.component';
import { ConfirmFbmStepComponent } from './confirm-fbm-step/confirm-fbm-step.component';
import { MatIconModule } from '@angular/material/icon';
import { MsalService } from '@azure/msal-angular';
import { ConsentAndSetup } from '../../models/consent-and-setup';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-registration-stepper',
  standalone: true,
  imports: [MatIconModule, MatDialogModule, MatButtonModule, MatStepperModule, MatInputModule, MatProgressSpinnerModule, ReactiveFormsModule, MatFormFieldModule, ConsentStepComponent, MarketplaceStepComponent, ReviewStepComponent, SubscriptionStepComponent, ConfirmFbmStepComponent],
  templateUrl: './registration-stepper.component.html',
  styleUrl: './registration-stepper.component.scss'
})
export class RegistrationStepperComponent implements OnInit, AfterContentChecked{

  @ViewChild('stepper') stepper!: MatStepper;

  confirming = false;

  fbmStepForm: FormGroup = new FormGroup({
    hasProfessionalAccount: new FormControl(null, [Validators.required, Validators.requiredTrue]),
  });
  consentStepForm: FormGroup = new FormGroup({
    hasAmazonConsent: new FormControl(null, [Validators.required, Validators.requiredTrue]),
    state: new FormControl(null, [Validators.required])
  });
  marketplaceStepForm: FormGroup = new FormGroup({
    region: new FormControl(null, [Validators.required]),
    marketplace: new FormControl(null, [Validators.required]),
    marketplaceConfirmed: new FormControl(false, [Validators.required, Validators.requiredTrue])
  });
  subscriptionStepForm: FormGroup = new FormGroup({
    paymentSuccess: new FormControl(false, [Validators.required, Validators.requiredTrue]),
  });
  reviewStepForm: FormGroup = new FormGroup({});

  constructor(
    private cdr: ChangeDetectorRef, 
    private authService: MsalService, 
    private matDialogRef: MatDialogRef<RegistrationStepperComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: ConsentAndSetup,
    private userService: UserService ){}

  ngOnInit(): void {
    if(this.data.hasConsented){
      this.consentStepForm.get('hasAmazonConsent')?.setValue(true);
    }
    if(this.data.hasConfirmedFBM){
      this.fbmStepForm.get('hasProfessionalAccount')?.setValue(true);
    }
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }


  back(){
    this.stepper.previous();
  }
  next(){
    this.stepper.next();
  }
  confirm(){
    this.confirming = true;
    this.userService.completeFirstTimeSetup().subscribe(() => {
      this.matDialogRef.close();
    })
  }
  get confirmVisible(){
    return this.stepper?.selectedIndex === this.stepper?.steps?.length - 1;
  }
  get backVisible(){
    return this.stepper?.selectedIndex > 0;
  }
  get nextDisabled(){
    if(this.stepper?.selectedIndex === 0){
      return this.fbmStepForm.invalid;
    }
    else if(this.stepper?.selectedIndex === 1){
      return this.marketplaceStepForm.invalid;
    }
    else if (this.stepper?.selectedIndex === 2){
      return this.consentStepForm.invalid;
    }
    else if (this.stepper?.selectedIndex === 3){
      return this.subscriptionStepForm.invalid && !this.subscriptionStepForm.get('paymentSkipped')?.value;
    }

    return false;
  }
  logout() {
    this.authService.logoutRedirect();
  }
}
